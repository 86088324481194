.about-business{
  position: relative;
  background: url('../../assets/images/dev-home-back-03.png') no-repeat center $base-400;
  background-size: cover;

  &:before{
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: rgba($base-400, 0.8);
	z-index: 1;
  }

  &__container{
	position: relative;
	z-index: 2;
  }

  &__card{
	border-radius: 24px;
    background-color: $main-100;
    padding: 16px;
	color: $base-400;
	transition: 0.3s all linear;

	@include breakpoint(sm){
	  padding: 24px;
	}

	@include breakpoint(xxl){
	  padding: 32px;
	}

	&_number{
	  width: 50px;
	  height: 50px;
	  border-radius: 50%;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  background-color: $accent-400;
	  font-size: 20px;
	  line-height: 20px;
	  font-weight: 700;
	  color: $white;
	  margin-bottom: 20px;

	  @include breakpoint(sm){
		width: 64px;
		height: 64px;
		font-size: 24px;
		line-height: 24px;
		margin-bottom: 40px;
	  }
	}
  }
}