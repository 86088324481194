@font-face {
    font-family: 'Alliance No.1';
    src: url('AllianceNo1-ExtraBoldItalic.eot');
    src: local('Alliance No.1 ExtraBold Italic'), local('AllianceNo.1-ExtraBoldItalic'),
        url('AllianceNo1-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo1-ExtraBoldItalic.woff2') format('woff2'),
        url('AllianceNo1-ExtraBoldItalic.woff') format('woff'),
        url('AllianceNo1-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Alliance No.1';
    src: url('AllianceNo1-Medium.eot');
    src: local('Alliance No.1 Medium'), local('AllianceNo.1-Medium'),
        url('AllianceNo1-Medium.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo1-Medium.woff2') format('woff2'),
        url('AllianceNo1-Medium.woff') format('woff'),
        url('AllianceNo1-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Alliance No.1';
    src: url('AllianceNo1-SemiBoldItalic.eot');
    src: local('Alliance No.1 SemiBold Italic'), local('AllianceNo.1-SemiBoldItalic'),
        url('AllianceNo1-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo1-SemiBoldItalic.woff2') format('woff2'),
        url('AllianceNo1-SemiBoldItalic.woff') format('woff'),
        url('AllianceNo1-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Alliance No.1';
    src: url('AllianceNo1-ExtraBold.eot');
    src: local('Alliance No.1 ExtraBold'), local('AllianceNo.1-ExtraBold'),
        url('AllianceNo1-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo1-ExtraBold.woff2') format('woff2'),
        url('AllianceNo1-ExtraBold.woff') format('woff'),
        url('AllianceNo1-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Alliance No.1';
    src: url('AllianceNo1-Black.eot');
    src: local('Alliance No.1 Black'), local('AllianceNo.1-Black'),
        url('AllianceNo1-Black.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo1-Black.woff2') format('woff2'),
        url('AllianceNo1-Black.woff') format('woff'),
        url('AllianceNo1-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Alliance No.1';
    src: url('AllianceNo1-Light.eot');
    src: local('Alliance No.1 Light'), local('AllianceNo.1-Light'),
        url('AllianceNo1-Light.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo1-Light.woff2') format('woff2'),
        url('AllianceNo1-Light.woff') format('woff'),
        url('AllianceNo1-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Alliance No.1';
    src: url('AllianceNo1-Regular.eot');
    src: local('Alliance No.1 Regular'), local('AllianceNo.1-Regular'),
        url('AllianceNo1-Regular.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo1-Regular.woff2') format('woff2'),
        url('AllianceNo1-Regular.woff') format('woff'),
        url('AllianceNo1-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Alliance No.1';
    src: url('AllianceNo1-BlackItalic.eot');
    src: local('Alliance No.1 Black Italic'), local('AllianceNo.1-BlackItalic'),
        url('AllianceNo1-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo1-BlackItalic.woff2') format('woff2'),
        url('AllianceNo1-BlackItalic.woff') format('woff'),
        url('AllianceNo1-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Alliance No.1';
    src: url('AllianceNo1-SemiBold.eot');
    src: local('Alliance No.1 SemiBold'), local('AllianceNo.1-SemiBold'),
        url('AllianceNo1-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo1-SemiBold.woff2') format('woff2'),
        url('AllianceNo1-SemiBold.woff') format('woff'),
        url('AllianceNo1-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Alliance No.1';
    src: url('AllianceNo1-Bold.eot');
    src: local('Alliance No.1 Bold'), local('AllianceNo.1-Bold'),
        url('AllianceNo1-Bold.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo1-Bold.woff2') format('woff2'),
        url('AllianceNo1-Bold.woff') format('woff'),
        url('AllianceNo1-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Alliance No.1';
    src: url('AllianceNo1-MediumItalic.eot');
    src: local('Alliance No.1 Medium Italic'), local('AllianceNo.1-MediumItalic'),
        url('AllianceNo1-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo1-MediumItalic.woff2') format('woff2'),
        url('AllianceNo1-MediumItalic.woff') format('woff'),
        url('AllianceNo1-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Alliance No.1';
    src: url('AllianceNo1-RegularItalic.eot');
    src: local('Alliance No.1 Regular Italic'), local('AllianceNo.1-RegularItalic'),
        url('AllianceNo1-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo1-RegularItalic.woff2') format('woff2'),
        url('AllianceNo1-RegularItalic.woff') format('woff'),
        url('AllianceNo1-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Alliance No.1';
    src: url('AllianceNo1-LightItalic.eot');
    src: local('Alliance No.1 Light Italic'), local('AllianceNo.1-LightItalic'),
        url('AllianceNo1-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo1-LightItalic.woff2') format('woff2'),
        url('AllianceNo1-LightItalic.woff') format('woff'),
        url('AllianceNo1-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Alliance No.1';
    src: url('AllianceNo1-BoldItalic.eot');
    src: local('Alliance No.1 Bold Italic'), local('AllianceNo.1-BoldItalic'),
        url('AllianceNo1-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo1-BoldItalic.woff2') format('woff2'),
        url('AllianceNo1-BoldItalic.woff') format('woff'),
        url('AllianceNo1-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Alliance No.2';
    src: url('AllianceNo2-LightItalic.eot');
    src: local('Alliance No.2 Light Italic'), local('AllianceNo.2-LightItalic'),
        url('AllianceNo2-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo2-LightItalic.woff2') format('woff2'),
        url('AllianceNo2-LightItalic.woff') format('woff'),
        url('AllianceNo2-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Alliance No.2';
    src: url('AllianceNo2-Light.eot');
    src: local('Alliance No.2 Light'), local('AllianceNo.2-Light'),
        url('AllianceNo2-Light.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo2-Light.woff2') format('woff2'),
        url('AllianceNo2-Light.woff') format('woff'),
        url('AllianceNo2-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Alliance No.2';
    src: url('AllianceNo2-Medium.eot');
    src: local('Alliance No.2 Medium'), local('AllianceNo.2-Medium'),
        url('AllianceNo2-Medium.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo2-Medium.woff2') format('woff2'),
        url('AllianceNo2-Medium.woff') format('woff'),
        url('AllianceNo2-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Alliance No.2';
    src: url('AllianceNo2-SemiBoldItalic.eot');
    src: local('Alliance No.2 SemiBold Italic'), local('AllianceNo.2-SemiBoldItalic'),
        url('AllianceNo2-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo2-SemiBoldItalic.woff2') format('woff2'),
        url('AllianceNo2-SemiBoldItalic.woff') format('woff'),
        url('AllianceNo2-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Alliance No.2';
    src: url('AllianceNo2-MediumItalic.eot');
    src: local('Alliance No.2 Medium Italic'), local('AllianceNo.2-MediumItalic'),
        url('AllianceNo2-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo2-MediumItalic.woff2') format('woff2'),
        url('AllianceNo2-MediumItalic.woff') format('woff'),
        url('AllianceNo2-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Alliance No.2';
    src: url('AllianceNo2-Regular.eot');
    src: local('Alliance No.2 Regular'), local('AllianceNo.2-Regular'),
        url('AllianceNo2-Regular.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo2-Regular.woff2') format('woff2'),
        url('AllianceNo2-Regular.woff') format('woff'),
        url('AllianceNo2-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Alliance No.2';
    src: url('AllianceNo2-BlackItalic.eot');
    src: local('Alliance No.2 Black Italic'), local('AllianceNo.2-BlackItalic'),
        url('AllianceNo2-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo2-BlackItalic.woff2') format('woff2'),
        url('AllianceNo2-BlackItalic.woff') format('woff'),
        url('AllianceNo2-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Alliance No.2';
    src: url('AllianceNo2-Bold.eot');
    src: local('Alliance No.2 Bold'), local('AllianceNo.2-Bold'),
        url('AllianceNo2-Bold.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo2-Bold.woff2') format('woff2'),
        url('AllianceNo2-Bold.woff') format('woff'),
        url('AllianceNo2-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Alliance No.2';
    src: url('AllianceNo2-BoldItalic.eot');
    src: local('Alliance No.2 Bold Italic'), local('AllianceNo.2-BoldItalic'),
        url('AllianceNo2-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo2-BoldItalic.woff2') format('woff2'),
        url('AllianceNo2-BoldItalic.woff') format('woff'),
        url('AllianceNo2-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Alliance No.2';
    src: url('AllianceNo2-RegularItalic.eot');
    src: local('Alliance No.2 Regular Italic'), local('AllianceNo.2-RegularItalic'),
        url('AllianceNo2-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo2-RegularItalic.woff2') format('woff2'),
        url('AllianceNo2-RegularItalic.woff') format('woff'),
        url('AllianceNo2-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Alliance No.2';
    src: url('AllianceNo2-ExtraBoldItalic.eot');
    src: local('Alliance No.2 ExtraBold Italic'), local('AllianceNo.2-ExtraBoldItalic'),
        url('AllianceNo2-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo2-ExtraBoldItalic.woff2') format('woff2'),
        url('AllianceNo2-ExtraBoldItalic.woff') format('woff'),
        url('AllianceNo2-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Alliance No.2';
    src: url('AllianceNo2-Black.eot');
    src: local('Alliance No.2 Black'), local('AllianceNo.2-Black'),
        url('AllianceNo2-Black.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo2-Black.woff2') format('woff2'),
        url('AllianceNo2-Black.woff') format('woff'),
        url('AllianceNo2-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Alliance No.2';
    src: url('AllianceNo2-SemiBold.eot');
    src: local('Alliance No.2 SemiBold'), local('AllianceNo.2-SemiBold'),
        url('AllianceNo2-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo2-SemiBold.woff2') format('woff2'),
        url('AllianceNo2-SemiBold.woff') format('woff'),
        url('AllianceNo2-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Alliance No.2';
    src: url('AllianceNo2-ExtraBold.eot');
    src: local('Alliance No.2 ExtraBold'), local('AllianceNo.2-ExtraBold'),
        url('AllianceNo2-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('AllianceNo2-ExtraBold.woff2') format('woff2'),
        url('AllianceNo2-ExtraBold.woff') format('woff'),
        url('AllianceNo2-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

