.index-cases{
  background: url('../../assets/images/dev-home-back-03.png') no-repeat center $base-400;
  background-size: cover;
  color: $white;
  padding-top: calc(100px + 50*((100vw - 360px) / 1098));
  padding-bottom: 200px;

  @include breakpoint(md){
	padding-bottom: 400px;
  }

  @include breakpoint(xxl){
	padding-top: 150px;
  }

  &__title{
	margin-bottom: calc(40px + 16*((100vw - 360px) / 1098));

	@include breakpoint(xxl){
	  margin-bottom: 56px;
	}
  }

  .case-card{
	border-color: $white;

	&__description{
	  color: $main-200;
	}

	&__button{
	  color: $white;
	}
  }
}