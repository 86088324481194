.about-advantages{

  &__heading{
	margin-bottom: calc(40px + 8*((100vw - 360px) / 1098));

	@include breakpoint(lg){
	  text-align: center;
	}

	@include breakpoint(xxl){
	  margin-bottom: 48px;
	}

	.subtitle{
	  @media (max-width: 991.5px){
		text-align: left;
	  }
	}
  }

  &__title{
	margin-bottom: calc(16px + 8*((100vw - 360px) / 1098));

	@include breakpoint(xxl){
	  margin-bottom: 24px;
	}
  }

  &__list{
	border: 1px solid $border-color;
	display: flex;
	flex-direction: column;

	@include breakpoint(xl){
	  flex-direction: row;
	}
  }

  &__item{
	width: 100%;
	padding: 24px 8px 24px 16px;
	display: flex;
	column-gap: calc(16px + 16*((100vw - 360px) / 1098));
	border-bottom: 1px solid $border-color;

	&:last-child{
	  border: 0;
	}

	@include breakpoint(xl){
	  border-bottom: 0;
	  border-right: 1px solid $border-color;
	  padding: 40px;
	}

	@include breakpoint(xxl){
	  column-gap: 32px;
	}

	&_icon{
	  flex-shrink: 0;
	  width: 80px;

	  img{
		width: 100%;
	  }
	}
  }
}