.swiper-button-prev,
.swiper-button-next {
  color: $white;
  background-color: $accent-400;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  opacity: 1;
  border: 0;
  transition: 0.5s all ease;

  &:hover{
    opacity: 0.8;
  }

  &:after{
	font-size: 20px;
  }
}


.swiper-pagination{
  &-bullet{
    background: rgba($base-400, 0.22);
    width: 0.5rem;
    height: 0.5rem;
	opacity: 1;

    &-active{
      background: $accent-400;
    }
  }
}

.swiper__nav{
  display: flex;
  align-items: center;
  gap: 32px;
  margin-top: 50px;

  &--center{
    justify-content: center;
  }

  &--left{
    justify-content: flex-start;
  }

  &--right{
    justify-content: flex-end;
  }

  .swiper-button-prev,
  .swiper-button-next{
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    margin-top: 0;
  }

  .swiper-pagination{
    position: relative;
    bottom: auto;
    left: auto;
    top: auto;
    right: auto;
    width: auto;
  }
}

.swiper-slide{
  display: flex;
  height: auto;
}



