.project-card{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  width: 100%;
  border-radius: 24px;
  padding: 32px;
  min-height: 370px;
  background-color: $main-100;
  color: $base-400;

  &__icon{
	width: 80px;
  }

  &__title{
	margin-bottom: 0;
  }

  &__button{
	display: flex;
	align-items: center;
	flex-direction: row;
	margin-top: auto;
	background-color: $white!important;
	color: $base-400!important;
	padding: 12px;
	width: fit-content;
	gap: 0;

	svg{
	  path{
		stroke: $base-400;
	  }
	}

	@include breakpoint(xl){
	  &:hover{
		padding: 12px 24px;
		column-gap: 10px;

		span{
		  font-size: 16px;
		}
	  }

	  span{
		margin-right: 0!important;
		font-size: 0;
	  }
	}

	span{
	  margin-right: 10px;
	}
  }

  &--light{
	background-color: $main-100;
	color: $base-400;

	.project-card__button{
	  background-color: $main-300!important;
	  color: $white!important;

	  svg{
		path{
		  stroke: $white;
		}
	  }
	}
  }

   &--middle{
	background-color: $main-300;
	color: $white;
  }

  &--dark{
	background-color: $main-500;
	color: $white;
  }
}

