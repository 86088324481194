.solutions{

  @media (max-width: 767px) {
	background-image: none!important;
	padding-bottom: 40px;
  }

  &__inner{
	display: grid;
	grid-template-columns: 1fr;
	column-gap: 20px;
	row-gap: 40px;

	@include breakpoint(xl){
	  grid-template-columns: 1fr 1fr;
	}
  }

  &__col{
	display: flex;
	flex-direction: column;
	gap: 15px;

	@include breakpoint(xl){
	  gap: 40px;
	}
  }

  &__title{
	text-align: center;

	@include breakpoint(xl){
	  text-align: left;
	}
  }

  &__item{
	margin-top: 40px;
	padding-top: calc(30px + 10*((100vw - 360px) / 1098));
	padding-bottom: calc(30px + 10*((100vw - 360px) / 1098));
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;

	@include breakpoint(xxl){
	  padding-top: 40px;
	  padding-bottom: 40px;
	}

	&_button{
	  color: $white!important;
	  margin-top: 40px;
	  display: flex;
	  align-items: center;
	  flex-direction: row;
	  gap: 16px;
	}
  }

  &__list{
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 16px;
	column-gap: 32px;
	color: $border-color;

	@include breakpoint(md){
	  grid-template-columns: 1fr 1fr;
	}

	li{
	  font-weight: 600;
	  line-height: normal;
	  position: relative;
	  padding-left: 28px;

	  &::before {
		content: "";
		position: absolute;
		top: 9px;
		left: 0;
		width: 11px;
		height: 2px;
		background-color: $border-color;
	  }
	}
  }
}