.section-dark{
  padding-top: calc(100px + 50*((100vw - 360px) / 1098));
  padding-bottom: calc(200px + -50*((100vw - 360px) / 1098));
  background-color: $base-400;
  color: $white;
  overflow: hidden;

  @include breakpoint(xxl){
	padding: 150px 0;
  }
}

.section-light{
  padding-top: calc(100px + 50*((100vw - 360px) / 1098));
  padding-bottom: calc(100px + 50*((100vw - 360px) / 1098));
  overflow: hidden;
	&.ribbon{
		padding: 10px;
	}

  @include breakpoint(xxl){
	padding: 150px 0;
  }

  &.pb-md-0{
	@include breakpoint(md){
	  padding-bottom: 0;
	}
  }
}

.section-heading{
  background: linear-gradient(100deg, $base-400, #131a2d);
  color: $white;
  padding-top: calc(100px + 100*((100vw - 360px) / 1098));
  padding-bottom: calc(40px + 40*((100vw - 360px) / 1098));
  overflow: hidden;

  @include breakpoint(xxl){
	padding-top: 200px;
	padding-bottom: 80px;
  }

  &__inner{
	border-bottom: 1px solid $main-200;
	display: flex;
	flex-direction: column;

	@include breakpoint(xl){
	  flex-direction: row;
	  justify-content: space-between;
	  align-items: flex-end;
	  gap: 30px;
	}
  }

  &__title{
	padding-bottom: calc(30px + 10*((100vw - 360px) / 1098));

	@include breakpoint(xl){
	  max-width: 800px;
	}

	@include breakpoint(xxl){
	  padding-bottom: 40px;
	}
  }

  &__image{
	max-width: 100%;
	max-height: 250px;
    margin:0 auto;
    display: flex;
	border-radius: 24px;

	@include breakpoint(xl){
	  max-width: 550px;
	  margin:0 0 0 auto;
	}

	&.mb_40{
	  margin-bottom: 40px!important;
	}
  }

  &__description{
	font-size: calc(16px + 8*((100vw - 360px) / 1098));
	padding-top: 40px;

	@include breakpoint(xl){
	  font-size: 24px;
	  max-width: 1040px;
	}
  }
}