.blog-heading{
  &__image{
	@media (max-width: 1200px){
	  display: none;
	}
  }
}

.blog-section{
  padding-bottom: 60px;

  &__list{
	display: grid;
	grid-template-columns: 1fr;
	gap: 20px;

	@include breakpoint(sm){
	  grid-template-columns: 1fr 1fr;
	}

	@include breakpoint(lg){
	  display: flex;
	  gap: 32px;
	}

	&_item{
	  width: 100%;
	  display: flex;
	  flex-direction: column;

	  @include breakpoint(xl){
		width: 33.33%;
	  }
	}
  }
}