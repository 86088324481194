.tabs {
  &__caption{
	li{
	  cursor: pointer;

	  &.active{
		cursor: initial;
	  }
	}
  }

  &__content {
    height: 0;
    overflow: hidden;

    &.active {
      height: auto;
      overflow: initial;
    }
  }
}