.project__slider{
  padding: 0 1rem;

  @include breakpoint(xl){
	margin-bottom: 70px;

	.swiper-wrapper {
	   transition-timing-function: linear!important;

	  &.hovered{
		//transition-duration: unset!important;
	  }
	 }

	&_nav{
	  display: none!important;
	}
  }

}