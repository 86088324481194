body{
  font-family: 'Alliance No.1', Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  background-color: $white;
  color: $base-400;
  font-size: 1rem;
}

a{
  color: $base-400;
  display: inline-block;
  text-decoration: none;
  line-height: 1.4;
  &:hover{
    color: $base-400;
  }
}

p{
  word-break: break-word;
  line-height: 1.4;

}

b, strong {
  font-weight: bold;
}

.container{
  max-width: 91.125rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 1.5rem;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  main{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.accent_color-400{
  color: $accent-400;
}

.main_color-200{
  color: $main-200;
}

.text-center{
  text-align: center;
}

[data-background-image] {
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
}

.pb_0{
  padding-bottom: 0!important;
}

