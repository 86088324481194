.about-hero{
  padding-top: calc(136px + 124*((100vw - 360px) / 1098));
  padding-bottom: calc(142px + 118*((100vw - 360px) / 1098));
  background: url('../../assets/images/dev-about-back-01.png') no-repeat center $base-400;
  background-size: cover;
  color: $white;

  @include breakpoint(xxl){
	padding: 260px 0;
  }

  &__title{
	font-size: calc(40px + 48*((100vw - 360px) / 1098));
	margin-bottom: calc(24px + 16*((100vw - 360px) / 1098));

	@include breakpoint(xxl){
	  font-size: 88px;
	  margin-bottom: 40px;
	}
  }

  &__description{
	font-size: calc(16px + 8*((100vw - 360px) / 1098));
	margin-bottom: calc(48px + 40*((100vw - 360px) / 1098));
	color: $main-200;

	@include breakpoint(xxl){
	  font-size: 24px;
	  margin-bottom: 88px;
	}
  }

  &__list{
	display: flex;
	flex-direction: column;

	@include breakpoint(md){
	  flex-direction: row;
	}

	&_item{
	  margin-bottom: 24px;
	  padding-bottom: 24px;
	  border-bottom: 1px solid $white;
	  display: flex;
	  flex-direction: column;
	  gap: 23px;
	  width: 100%;

	  @include breakpoint(md){
		padding:0 32px;
		border-left: 1px solid $white!important;
		border-bottom: 0;
		width: 25%;
	  }

	  &:last-child{
		border: 0;
	  }
	}
  }
}