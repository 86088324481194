.cases-section{
  &__list{
	display: grid;
	grid-template-columns: 1fr;
	gap: 40px;

	@include breakpoint(md){
	  grid-template-columns: 1fr 1fr;
	  gap: 40px 32px;
	}

	@include breakpoint(xl){
	  grid-template-columns: 1fr 1fr 1fr;
	}

	.case-card{
	  @media (max-width: 767.5px){
		border-left: 0;
		padding-left: 0;
	  }
	}
  }
}

.cases-detail{
  &__list{
	display: grid;
	grid-template-columns: 1fr;
	gap: 32px;
	margin-bottom: 32px;

	@include breakpoint(lg){
	  grid-template-columns: 1fr 1fr;
	}

	&_item{
	  padding: calc(24px + 16*((100vw - 360px) / 1098)) calc(24px + 64*((100vw - 360px) / 1098));
	  background-color: $main-100;
	  border-radius: 24px;

	  @include breakpoint(sm){
		font-size: 20px;
	  }

	  @include breakpoint(xxl){
		padding: 40px 88px;
	  }

	  &_img{
		width: 40px;
		margin-bottom: 40px;

		@include breakpoint(sm){
		  width: 80px;
		}
	  }

	  &_title{
        font-size: calc(24px + 12*((100vw - 360px) / 1098));
		font-weight: 800;
    	line-height: 107.415%;
		margin-bottom: 16px;

		@include breakpoint(xxl){
		  font-size: 36px;
		}
	  }
	}
  }

  &__testimonial{
	padding: calc(24px + 16*((100vw - 360px) / 1098)) calc(24px + 64*((100vw - 360px) / 1098));
	border-radius: 24px;
	background-color: $main-300;
	color: $white;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;

	@include breakpoint(sm){
	  font-size: 20px;
	}

	@include breakpoint(lg){
	  flex-direction: row;
	  column-gap: 40px;
	}

	@include breakpoint(xxl){
	  padding: 40px 88px;
	}

	&_img{
	  width: 40px;
	  flex-shrink: 0;

	  @include breakpoint(sm){
		width: 80px;
	  }
	}

	&_title{
	  font-size: calc(24px + 12*((100vw - 360px) / 1098));
	  font-weight: 800;
	  line-height: 107.415%;
	  margin-bottom: 16px;
	  text-align: center;

	  @include breakpoint(lg){
		text-align: left;
	  }

	  @include breakpoint(xxl){
		font-size: 36px;
	  }
	}
  }
}