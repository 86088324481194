.button{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  outline: none;
  border: 0;
  border-radius: 100px;
  color: $white!important;
  font-size: 1rem;
  font-weight: 600;
  padding: 15px;
  background: $accent-400;
  line-height: normal;
  transition: all 0.3s ease 0s;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  max-width: 200px;

  &--animate{
	background: linear-gradient(-45deg, $main-300, $main-400, $accent-400, $accent-400, $accent-300);
    background-size: 400% 400%;
    animation: animate_gradient 5s ease infinite;
  }

  &:hover{
	background: $main-300;
  }
}

@keyframes animate_gradient{
  0% {
	  background-position: 0 50%;
  }
  50% {
	  background-position: 100% 50%;
  }
  100% {
	  background-position: 0 50%;
  }
}

.button__arrow-up{
  display: flex;
  align-items: center;
  flex-direction: row;
  //justify-content: space-between;
  gap: 16px;

  &:hover{
	color: $main-300!important;

	svg{
	  path{
		stroke: $main-300;
	  }
	}
  }

  svg, img{
	width: 24px;
	height: 24px;
	transition: all 0.3s ease 0s;
	display: block;
	flex-shrink: 0;
  }
}