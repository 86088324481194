.index-expertise{
  padding-top: 100px;
  padding-bottom: calc(100px + 50*((100vw - 360px) / 1098));

  @include breakpoint(xxl){
	padding-bottom: 150px;
  }

  &__title{
	text-align: center;
	margin-bottom: 32px;

	@include breakpoint(xxl){
	  margin-bottom: 64px;
	}
  }

  &__tabs{
	background-color: $main-100;
	border-radius: 24px;

	.tabs__caption{
	  display: flex;
	  background-color: $white;

	  li{
		font-size: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		text-align: center;
		padding: 35px 0;
		color: $base-300;
		font-weight: bold;
		border-radius: 24px 24px 0 0;
		transition: 0.5s all ease;

		@include breakpoint(md){
		  font-size: 24px;
		}


		&.active{
		  color: $base-400;
		  background-color: $main-100;
		}

		&:hover{
		  color: $base-400;
		}
	  }
	}
  }

  &__list{
	padding:calc(32px + 32*((100vw - 360px) / 1098)) calc(32px + 56*((100vw - 360px) / 1098));
	display: grid;
	grid-template-columns: 1fr;
	gap: 30px;

	@include breakpoint(md){
	  grid-template-columns: 1fr 1fr;
	}

	@include breakpoint(lg){
	  grid-template-columns: 1fr 1fr 1fr;
	}

	@include breakpoint(xxl){
	  padding: 64px 88px;
	}

	li{
	  a{
		font-size: 20px;
		font-weight: 700;
		width: 100%;
		max-width: 323px;

		&:hover{
		  color: $main-300;

		  svg{
			transform: rotate(90deg);
		  }
		}
	  }
	}
  }

  &__button{
	margin-top: calc(50px + 14*((100vw - 360px) / 1098));
	display: flex;
	align-items: center;
	justify-content: center;

	@include breakpoint(xxl){
	  margin-top: 64px;
	}
  }
}