.hidden_body {
  overflow: hidden;

  .header{
	background-color: $base-400;
  }
}

.header{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  padding: 32px 0;

  &__inner{
	display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo{
	color: $white!important;
	font-weight: bold;
	position: relative;
	z-index: 10;
  }

  nav{
	position: fixed;
	height: 100%;
	width: 100%;
	z-index: 9999;
	transition: right .3s ease;
	right: -100%;
	top: 90px;
	overflow-y: auto;
	scrollbar-color: transparent transparent;
	scrollbar-width: thin;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	&.active {
	  right: 0;
	}

	@include breakpoint(xl){
	  display: block;
	  position: static;
	  right: auto;
	  top: auto;
	  margin: 0 auto;
	  background-color: transparent;
	  height: auto;
	  width: auto;
	  z-index: auto;
	}
  }

  &__nav{
	width: 100%;
	display: flex;
	flex-direction: column;
    column-gap: 64px;
	row-gap: 40px;
	background-color: $base-400;
	flex-grow: 1;
	padding: 24px 24px 0;

	@include breakpoint(xl){
	  flex-direction: row;
	  align-items: center;
	  background-color: transparent;
	  padding: 0;
	}

	&-item{

	  &:hover{
		@include breakpoint(xl){
		  &.submenu_show{
			background: url("../../assets/images/dev-arrow-top.svg") no-repeat right 6px;
			background-size: 12px 12px;
		  }
		}

		.header__nav-link{
		  color: $accent-400;
		}
	  }
	}

	&-link{
	  color: $white;
	  font-size: 24px;
	  font-weight: 600;
	  line-height: normal;
	  transition: all 0.3s ease 0s;
	  cursor: pointer;

	  @include breakpoint(xl){
		color: $main-200;
		font-size: 16px;
		position: relative;
		z-index: 10;
	  }

	  &:hover{
		color: $accent-400;
	  }
	}

	.submenu_show{
	  padding-right: 40px;
	  position: relative;

	  @include breakpoint(xl){
		padding-right: 20px;
		position: static;
		background: url("../../assets/images/dev-arrow-down.svg") no-repeat right 6px;
		background-size: 12px 12px;
	  }

	  &:after{
		content: '';
		background: url("../../assets/images/arrow-white.svg")no-repeat center;
		background-size: 100% 100%;
		position: absolute;
		right: 0;
		top: 3px;
		width: 24px;
		height: 24px;

		@include breakpoint(xl){
		  display: none;
		}
	  }

	  &.active{
		&:after{
		  transform: rotate(90deg);
		}
	  }

	}

	&-submenu{
	  display: none;

	  @include breakpoint(xl){
		position: absolute;
		top: 30px;
		left: 50%;
		transform: translate(-50%, 0);
		width: 100%;
		max-width: 1410px;
		padding-top: 40px;
	  }

	  ul{
		display: grid;
		grid-template-columns: 1fr;
		gap: 20px;
		margin-top: 25px;

		@include breakpoint(xl){
		  padding-top: 24px;
		  padding-right: 24px;
		  padding-bottom: 24px;
		  padding-left: calc(100px + 400*((100vw - 360px) / 1098));
		  border-radius: 24px;
		  background-color: $main-500;
		  grid-template-columns: 1fr 1fr;
		  gap:0 20px;
		  transition: all 0.3s ease 0s;
		}

		@include breakpoint(xxl){
		  padding-left: 500px;
		}

		li{
		  a{
			color: $main-200;
			font-weight: 600;
			transition: all 0.3s ease 0s;

			@include breakpoint(xl){
			  padding: 10px 0 10px 24px;
			  border-left: 1px solid $white;
			}

			&:hover{
			  color: $accent-400;
			}
		  }
		}
	  }
	}
  }

  &__lang{
	position: relative;
	z-index: 10;
	margin-left: auto;
	flex-shrink: 0;
	background-color: transparent;
	width: 3.5rem;
	height: 30px;
	border: 1.5px solid $white;
	border-radius: 4px;
	outline: none;
	color: $white;
	text-align: left;
	padding-left: 0.5rem;
	font-family: 'Alliance No.1', sans-serif;
	font-weight: normal;
	background-image: url("../../assets/images/dev-arrow-down.svg");
	background-repeat: no-repeat;
	background-size: 12px 12px;
	background-position: 90% center;
	-webkit-appearance: none;

	@include breakpoint(xl){
	  border: 0;
	}

	option{
	  color: $base-400;
	}
  }
}

.navbar_toggler{
  width: 30px;
  height: 30px;
  border: 1.5px solid $white;
  border-radius: 4px;
  display: block;
  background: url("../../assets/images/dev-menu-open.svg") no-repeat center;
  background-size: 15px;
  margin-left: 1rem;

  @include breakpoint(xl){
	display: none;
  }

  &.active{
	background: url("../../assets/images/dev-close.svg") no-repeat center;
	background-size: 12px;
  }
}