.case-card{
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  border-left: 1px solid $base-400;

  @include breakpoint(lg){
	padding-left: 40px;
  }

  &__icon{
	width: 80px;
	margin-bottom: 24px;
  }

  &__image{
    height: 150px;
    border-radius: 24px;
	margin-bottom: 24px;
	display: block;
	width: 100%;
	overflow: hidden;
	transition: 0.5s all ease;

	&:hover{
	  img{
		transform: scale(1.1);
	  }
	}

	img{
	  width: 100%;
	  height: 100%;
	  object-fit: cover;
	  transition: 0.5s all ease;
	}
  }

  &__title{
	font-size: 20px;
	font-weight: bold;
	line-height: normal;
	margin-bottom: 16px;
  }

  &__description{
	margin-bottom: 16px;
  }

  &__button{
	margin-top: auto;
	margin-right: auto;
	font-weight: 600;
  }
}