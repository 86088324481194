.form{

  &__fildset{
	margin-bottom: 24px;

	@include breakpoint(md){
	  margin-bottom: 32px;
	}

	.button{
	  height: 50px;
	  width: 100%;
	  max-width: 100%;
	}
  }

  &__grid{
	@include breakpoint(md){
	  display: grid;
	  grid-template-columns: 1fr 1fr;
	  gap: 0 32px;
	}
  }

  &__input{
	display: block;
	width: 100%;
	border-radius: 100px;
	border: 0;
	color: $base-300;
	background-color: $white;
	padding: 0 24px;
	font-family: 'Alliance No.1', sans-serif;
	height: 50px;
	font-size: 1rem;

	&::placeholder{
	  color: $base-300;
	  font-family: 'Alliance No.1', sans-serif;
	  font-style: normal;
	  font-weight: 400;
	  line-height: normal;
	}

  }

  textarea{
	height: 5rem;
	resize: none;
	padding: 15px 24px;
	border-radius: 1.25rem;
  }

  &__select, select{
	background-image: url("../../assets/images/select-arrow-down.svg");
	background-repeat: no-repeat;
	background-size: 12px;
	background-position: 93% center;
	-webkit-appearance: none;
  }

  &__label{
	margin-bottom: 0.81rem;
  }

  &__button{
	margin: 0 auto;
  }
}