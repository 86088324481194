.index-advantages{
  padding-top: calc(100px + 50*((100vw - 360px) / 1098));

  @include breakpoint(xxl){
	padding-top: 158px;
  }

  &__title{
	text-align: center;
	margin-bottom: calc(40px + 8*((100vw - 360px) / 1098));

	@include breakpoint(xxl){
	  text-align: left;
	  margin-bottom: 48px;
	}
  }

  &__list{
	border-bottom: 1px solid $border-color;

	@include breakpoint(lg){
	  display: flex;
	  border-top: 1px solid $border-color;
	}

	&_item{
	  border-top: 1px solid $border-color;
	  width: 100%;
	  padding: 24px 0;
	  display: flex;
	  flex-direction: column;
	  justify-content: center;
	  overflow: hidden;

	  @include breakpoint(lg){
		padding: 30px 20px;
		border-top: 0;
		border-right: 1px solid $border-color;

		&:last-child{
		  border-right: 0;
		}
	  }

	  p span{
		padding-left: 8px;
	  }

	}
  }
}