.services-advantages {
  h2{
      text-align: center;
      padding: 1rem;
      margin: 1rem;
        color: $accent-400;
    }
  .advantages-list {
    display: grid;
    height: 100%;
    font-size: 1rem;
    @include breakpoint(md) {
      font-size: 1.5rem;
    }
    @include breakpoint(xl) {
      font-size: 2rem;
    }

    ul {
      list-style-type: disc;
      padding-left: 15px;
      margin: 20px 0;
    }

    ul li {
      margin-bottom: 10px;
      line-height: 1.6;
      color: $base-400;
    }

    ul li:last-child {
      margin-bottom: 0;
    }

    ul li:hover {
      color: $main-500;
    }
  }

  &__list {
    border: 1px solid $border-color;
    display: flex;
    flex-direction: column;
    margin-top: calc(40px + 16 * ((100vw - 360px) / 1098));

    @include breakpoint(md) {
      flex-direction: row;
    }

    @include breakpoint(xxl) {
      margin-top: 56px;
    }
  }

  &__item {
    width: 100%;
    padding: 24px 8px 24px 16px;
    column-gap: calc(16px + 16 * ((100vw - 360px) / 1098));
    border-bottom: 1px solid $border-color;
    display: flex;
    flex-direction: column;
    gap: 16px;
    text-align: center;

    &:last-child {
      border: 0;
    }

    @include breakpoint(md) {
      border-bottom: 0;
      border-right: 1px solid $border-color;
      padding: 40px 20px 20px;
    }

    @include breakpoint(xxl) {
      column-gap: 32px;
      padding: 40px 32px 20px 32px;
    }

    &_icon {
      flex-shrink: 0;
      width: 80px;
      margin: auto;

      @include breakpoint(xl) {
        margin-bottom: 24px;
      }

      img {
        width: 100%;
      }
    }
  }
}

.services__list {
  margin-top: calc(40px + 95 * ((100vw - 360px) / 1098));
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;

  @include breakpoint(sm) {
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoint(lg) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include breakpoint(xxl) {
    margin-top: 135px;
  }

  &_item {

    &_link {
      padding: 24px 16px;
      background-color: $main-100;
      border-radius: 24px;
      transition: all 0.3s ease 0s;
      display: flex;
      align-items: center;
      flex-grow: 1;
      gap: 20px;
      font-size: 20px;
      line-height: normal;
      font-weight: 700;
      font-style: normal;

      @include breakpoint(xxl) {
        padding: 32px;
        gap: 32px;
      }

      &:hover {
        background-color: $main-300;
      }

      img {
        width: 40px;
        flex-shrink: 0;

        @include breakpoint(md) {
          width: 80px;
        }
      }
    }
  }
}

.services-detail {

  &__project-title {
    padding-top: calc(100px + 50 * ((100vw - 360px) / 1098));

    @include breakpoint(xxl) {
      padding-top: 150px;
    }
  }

  &__title {
    margin-bottom: calc(15px + 41 * ((100vw - 360px) / 1098));

    @include breakpoint(xxl) {
      margin-bottom: 56px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;

    @include breakpoint(md) {
      row-gap: 57px;
      column-gap: 32px;
    }

    @include breakpoint(lg) {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(xl) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .case-card {
    @media (max-width: 767.5px) {
      padding: 0 0 25px;
      border-left: 0;
      border-bottom: 1px solid $border-color;
    }

    &__description {
      margin-bottom: 0;
    }
  }
}