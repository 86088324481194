h1, .h1{
  font-size: calc(32px + 64*((100vw - 360px) / 1098));
  font-weight: 800;
  font-style: normal;
  line-height: 1;

  @include breakpoint(xxl){
	font-size: 96px;
  }
}

h2, .h2{
  font-size: calc(36px + 28*((100vw - 360px) / 1098));
  font-weight: 800;
  font-style: normal;
  line-height: 1.22;

  @include breakpoint(xxl){
	font-size: 64px;
  }
}

h3, .h3{
  font-size: calc(30px + 18*((100vw - 360px) / 1098));
  font-weight: 800;
  font-style: normal;
  line-height: 1.22;

  @include breakpoint(xxl){
	font-size: 48px;
  }
}

h4, .h4{
  font-size: 20px;
  line-height: normal;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 16px;
}

h5, .h5{
  font-size: 1.125rem;
  line-height: 1.45;
  font-style: normal;
  color: $base-400;

  @include breakpoint(md){
    font-size:1.25rem;
  }

  @include breakpoint(xxl){
    font-size:1.5rem;
  }
}

h6, .h6{
  font-size: 1rem;
  line-height: 1.5;
  font-style: normal;
  color: $base-400;

  @include breakpoint(md){
    font-size:1.1rem;
  }

  @include breakpoint(xxl){
    font-size:1.25rem;
    line-height: 1.3;
  }
}

.subtitle{
  color: $accent-400;
  text-align: center;
  font-size: calc(16px + 8*((100vw - 360px) / 1098));
  font-style: normal;
  font-weight: 500;
  line-height: 107.415%;
  text-transform: uppercase;
  margin-bottom: 24px;

  @include breakpoint(xxl){
	font-size: 24px;
  }
}

.center-title{
  text-align: center;
  margin-bottom: calc(40px + 24*((100vw - 360px) / 1098));

  @include breakpoint(xxl){
	margin-bottom: 64px;
  }
}

