.contacts{
  margin-top: calc(75px + 75*((100vw - 360px) / 1098));
  display: flex;
  flex-direction: column;
  gap: 32px;

  @include breakpoint(lg){
	margin-bottom: 70px;
	flex-direction: row;
  }

  @include breakpoint(xxl){
	margin-top: 150px;
  }

  &__list{
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 16px;
  }

  &__map{
	border-radius: 24px;
	background-color: $main-100;
	width: 100%;

	iframe{
	  width: 100%;
	  height: 100%;
	  min-height: 400px;
	  border-radius: 24px;
	}
  }

  &__title{
	margin-bottom: 30px;
  }

  &__link{
	border-radius: 16px;
	background: rgba(255, 255, 255, .1);
	display: flex;
	align-items: center;
	width: 100%;
	padding: 10px 16px;
	font-size: calc(18px + 4*((100vw - 360px) / 1098));
	color: $accent-400;
	font-weight: 600;
	line-height: normal;
	transition: all 0.3s ease 0s;
	gap: 16px;
	min-height: 72px;

	@include breakpoint(sm){
	  padding: 10px 32px;
	}

	@include breakpoint(xxl){
	  font-size: 24px;
	}

	svg, img{
	  width: 33px;
	  transition: all 0.3s ease 0s;
	  flex-shrink: 0;
	}
  }

  a.contacts__link:hover{
	color: $main-300!important;

	svg{
	  path{
		stroke:$main-300;
	  }
	}
  }
}