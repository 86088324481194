.index-blog{
  padding-top: calc(100px + 50*((100vw - 360px) / 1098));
  padding-bottom: 100px;

  @include breakpoint(md){
	padding-bottom: 0;
  }

  @include breakpoint(xxl){
	padding-top: 150px;
  }

  &__heading{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	row-gap: 20px;
	margin-bottom: 32px;

	@include breakpoint(md){
	  padding-bottom: 32px;
	  border-bottom: 1px solid $border-color;
	  flex-direction: row;
	}
  }

  &__list{
	display: grid;
	grid-template-columns: 1fr;
	gap: 20px;

	@include breakpoint(sm){
	  grid-template-columns: 1fr 1fr;
	}

	@include breakpoint(lg){
	  display: flex;
	  gap: 32px;
	}

	&_item{
	  width: 100%;
	  display: flex;
	  flex-direction: column;

	  @include breakpoint(xl){
		width: 33.33%;
	  }

	  @include breakpoint(xxl){
		&:hover{
		  width: 40%;
		}
	  }
	}
  }


}