.footer{
  padding-top: calc(40px + 40*((100vw - 360px) / 1098));
  padding-bottom: 40px;
  background-color: $base-400;
  color: $white;
  margin-top: auto;
  position: relative;

  &__row{
	display: flex;
	flex-wrap: wrap;

	@include breakpoint(xl){
	  display: grid;
	  grid-template-columns: 1fr 1fr;
	  column-gap: 60px;
	}

	&--bottom{
	  margin-top: 32px;
	  padding-top: 32px;
	  border-top: 1px solid $main-200;

	  @include breakpoint(xxl){
		margin-top: 40px;
		padding-top: 40px;
	  }
	}
  }

  &__col{
	border-bottom: 1px solid $main-200;
	padding-bottom: 2rem;
	margin-bottom: 2rem;
	width: 100%;

	@include breakpoint(xl){
	  border: 0;
	  padding-bottom: 0;
	  margin-bottom: 0;
	}

	&:last-child{
	  border: 0;
	  padding-bottom: 0;
	  margin-bottom: 0;
	}

	&--social{
	  display: flex;
	  flex-direction: column;
	  gap: 30px;

	  @include breakpoint(sm){
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	  }
	}

	&--nav{
	  display: flex;
	  flex-direction: column;

	  @include breakpoint(md){
		flex-direction: row;
		justify-content: space-between;
	  }

	  @include breakpoint(xl){
		column-gap: 60px;
	  }
	}

	&_item{
	  border-bottom: 1px solid $main-200;
	  padding-bottom: 2rem;
	  margin-bottom: 2rem;

	  @include breakpoint(md){
		padding-bottom: 0;
		border-bottom: 0;
		min-width: 150px;
		gap: 1rem;
	  }

	  @include breakpoint(xl){
		min-width: auto;
		width: 100%;
		position: relative;
		margin: 0;
		padding: 0;

		&:after{
		  content: '';
		  width: 1px;
		  height: 100%;
		  background-color: $white;
		  position: absolute;
		  top: 0;
		  left: -30px;
		}

	  }

	  &:last-child{
		border: 0;
		padding-bottom: 0;
		margin-bottom: 0;
	  }
	}
  }

  &__menu-title{
	color: $white!important;
    font-weight: 600;
    margin-bottom: 30px;
    display: flex;
  }

  a.footer__menu-title{
	&:hover{
	  text-decoration: underline;
	}
  }

  &__nav{
	display: flex;
    flex-direction: column;
    row-gap: 15px;

	li{
	  a{
		color: $main-200;
		line-height: 1;

		&:hover{
		  text-decoration: underline;
		}
	  }
	}
  }

  &__contacts{
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
	color: $main-200;

	@include breakpoint(md){
	  row-gap: 10px;
	}

	&_item{
	  display: grid;
	  grid-template-columns: 1fr;
	  gap: 10px;

	  @include breakpoint(md){
		grid-template-columns: 190px 1fr;
		gap: 26px;
	  }

	  &_title{
		font-weight: 600;
	  }
	}

	a{
	  color: $main-200;

	  &:hover{
		text-decoration: underline;
	  }
	}
  }

  &__description{
	margin-top: 1rem;
	margin-bottom: 2rem;
	color: $main-200;
  }

  &__logo{
	display: block;
	color: $white!important;
	font-weight: bold;
  }

  &__policy-list{
	flex-direction: column;
	display: flex;
	row-gap: 10px;

	@include breakpoint(sm){
	  flex-direction: row;
	  justify-content: space-between;
	  align-items: center;
	  flex-wrap: wrap;
	}

	@include breakpoint(xl){
	  position: relative;

	  &:after{
		content: '';
		width: 1px;
		height: 100%;
		background-color: $white;
		position: absolute;
		top: 0;
		right: -30px;
	  }
	}

	li{
	  a{
		color: $white;
		font-weight: 600;
		line-height: normal;
		transition: all 0.3s ease 0s;

		&:hover{
		  opacity: 0.8;
		}
	  }
	}
  }

  &__social{
    display: flex;
	flex-wrap: wrap;
    align-items: center;
    column-gap: 2rem;

	&-link{
	  display: flex;
	  align-items: center;
	  column-gap: 1rem;
	  color: $white!important;
	  font-weight: 600;
	  line-height: normal;
	  transition: all 0.3s ease 0s;

	  &:hover{
		opacity: 0.8;
	  }
	}
  }
}