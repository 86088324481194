.form-section{
  @include breakpoint(md){
	padding-top: calc(100px + 50*((100vw - 360px) / 1098));
	padding-bottom: calc(100px + 50*((100vw - 360px) / 1098));
  }

  @include breakpoint(xxl){
    padding-top: 150px;
    padding-bottom: 150px;
  }

  &__container{
	@media (max-width: 767.5px) {
	  padding: 0;
	}
  }

  &__inner{
    background: url('../../assets/images/dev-form-back-02.png') no-repeat center $base-400;
    background-size: cover;
    padding-top: 100px;
    padding-left: 16px;
    padding-right: 16px;
	padding-bottom: calc(210px + -36*((100vw - 360px) / 1098));
    overflow: hidden;

	@include breakpoint(md){
	  border-radius: 24px;
	}

	@include breakpoint(xxl){
	  padding-bottom: 174px;
	}
  }

  &__title{
	text-align: center;
	margin-bottom: 40px;
	color: $white;
  }

  &__form{
	max-width: 700px;
	margin: 0 auto;
  }
}