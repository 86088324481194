.accordion {
  width: 100%;
  transition: 0.3s linear all;

  &__active {
    .accordion__content {

      &_wrapper{
        grid-template-rows: 1fr;
      }
    }

    .accordion__intro {
      &::after {
		background-color: $accent-400;
        transform: translateY(-50%) rotate(90deg);
      }
    }
  }

  &__content {
    overflow: hidden;

    &_wrapper{
      display: grid;
      grid-template-rows: 0fr;
	  transition: grid-template-rows .5s linear;
    }
  }

  &__intro {
	background-color: $main-100;
	border-radius: 16px;
	padding: 30px 80px 30px 25px;
    position: relative;
    transition: .3s all ease-in-out;
	color: $base-400;

	@include breakpoint(md){
	  padding: 26px 100px 26px 40px;
	}

    &::after {
      content: "";
      position: absolute;
      text-align: center;
      background: url('../../assets/images/arrow-white.svg') no-repeat center $main-300;
      background-size: 25px 25px;
      transition: all .35s;
      border-radius: 50%;
	  top: 50%;
      transform: translateY(-50%);
	  right: 30px;
      cursor: pointer;
	  width: 48px;
	  height: 48px;

	  @include breakpoint(md){
		right: 40px;
	  }
    }

	&_title{
	  font-weight: 700;
	  font-size: calc(18px + 6*((100vw - 360px) / 1098));
	  max-width: 290px;
	  line-height: 107.415%;

	  @include breakpoint(xxl){
		font-size: 24px;
	  }
	}
  }
}