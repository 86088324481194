.post_content {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;


  a {
    color: $main-300;
    transition: 0.5s ease;
    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    list-style: disc;
    margin-left: 1rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;


    li {
      position: relative;
      line-height: 1.4;
    }
  }

  ol {
    counter-reset: item;
	display: flex;
	flex-direction: column;
	gap: 1rem;

    & > li {
      position: relative;
      padding: 5px 0;
      line-height: 1.4;

      &::before {
        content: counters(item, ".") ". ";
        counter-increment: item;
        display: inline-block;
        margin-right: 0.5em;
        text-align: right;
      }
    }
  }

  blockquote {
    font-size: 1.2rem;
    margin: 20px 0;
    line-height: 1.3;
    position: relative;
    padding: 10px 0 10px 20px;
    border-left: 5px solid $accent-400;

    p {
      padding: 0;
    }
  }

  .img {
    display: block;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  table {
    width: 100%
  }
}