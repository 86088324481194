.blog-card{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  border-radius: 24px;
  background-color: $main-100;
  padding: 16px;

  @include breakpoint(xl){
	padding: 24px;
  }

  &__image{
    position: relative;
    height: 200px;
    border-radius: 24px;
    overflow: hidden;
	background-color: $main-400;
	margin-bottom: 24px;

	img{
	  display: block;
	  margin: 0 auto;
	  max-width: 100%;
	  max-height: 100%;
	  object-fit: cover;
	  object-position: center;
	}
  }

  &__title{
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 8px;
	line-height: normal;
	max-width: 400px;

	@include breakpoint(xl){
	  font-size: 24px;
	}
  }

  &__icon{
	margin-top: calc(50px + 34*((100vw - 360px) / 1098));
	display: block;

	@include breakpoint(xxl){
	  margin-top: 84px;
	}
  }
}