@font-face {
	font-family: 'Dancing Script';
	src: url('DancingScript-Regular.woff2') format('woff2');
	font-weight: 1;
	font-style: normal;
}
@font-face {
	font-family: 'Dancing Script';
	src: url('DancingScript-Regular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Dancing Script';
	src: url('DancingScript-Medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Dancing Script';
	src: url('DancingScript-SemiBold.woff2') format('woff2');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Dancing Script bold';
	src: url('DancingScript-Bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}
