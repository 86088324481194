.index-hero {
  position: relative;
  background-color: $base-400;
  color: $white;
  padding-top: calc(140px + 90 * ((100vw - 360px) / 1098));

  @include breakpoint(xxl) {
    padding-top: 230px;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .01) 11.28%, rgba(255, 255, 255, .04) 20.11%, rgba(255, 255, 255, .08) 26.91%, rgba(255, 255, 255, .13) 32.09%, rgba(255, 255, 255, .19) 36.06%, rgba(255, 255, 255, .26) 39.24%, rgba(255, 255, 255, .33) 42.03%, rgba(255, 255, 255, .41) 44.85%, rgba(255, 255, 255, .5) 48.11%, rgba(255, 255, 255, .58) 52.22%, rgba(255, 255, 255, .67) 57.6%, rgba(255, 255, 255, .76) 64.65%, rgba(255, 255, 255, .84) 73.79%, rgba(255, 255, 255, .92) 85.44%, #FFF 100%);
  }

  &__bg {
    height: 450px;
    width: 100%;
    object-fit: cover;
    margin-top: 50px;
  }

  &__label {
    position: relative;
    overflow: hidden;
    border-radius: 4px 4px 0 4px;
    background: $main-200;
    font-size: calc(20px + 16 * ((100vw - 360px) / 1098));
    font-style: normal;
    font-weight: 800;
    line-height: 107.415%;
    color: $base-400;
    padding: 12px 40px 12px 20px;
    margin-bottom: 20px;
    border: 1px solid $base-400;

    @include breakpoint(md) {
      padding: 12px 70px 12px 32px;
    }

    @include breakpoint(xxl) {
      font-size: 36px;
      top: 30px;
    }

    p {
      font-family: 'Dancing Script bold', sans-serif;
      line-height: 1;
      font-size: 1.6rem;
      padding-bottom: 2rem;
      @include breakpoint(sm) {
        font-size: 2.2rem;
      }
      @include breakpoint(md) {
        font-size: 3rem;
      }
    }

    .circle {
      position: absolute;
      border-radius: 50%;
      background-color: $base-400;
      animation: floatAnimation linear infinite alternate;

      &--1 {
        width: 63px;
        height: 63px;
        bottom: -26px;
        right: 105px;
        animation-duration: 2s;

        @include breakpoint(xl) {
          right: 70px;
        }
      }

      &--2 {
        width: 100px;
        height: 100px;
        bottom: -65px;
        right: 41px;
        animation-duration: 1s;

        @include breakpoint(xl) {
          right: 5px;
        }
      }

      &--3 {
        width: 40px;
        height: 40px;
        bottom: 10px;
        right: 40px;
        animation-duration: 2s;
      }

      &--4 {
        width: 37px;
        height: 37px;
        top: 17px;
        right: 17px;
        animation-duration: 3s;
      }

      &--5 {
        width: 33px;
        height: 33px;
        bottom: -20px;
        left: 20px;
      }
    }
  }

  &__title {
    position: relative;

    h1 {
      font-size: 2rem;

      @include breakpoint(xl) {
        font-size: 3rem;
      }

      @include breakpoint(xxl) {
        font-size: 3.5rem;
        padding-top: 1rem;
      }

      span {
        display: block;
      }
    }
  }

  &__description {
    margin-top: 56px;
    padding-top: 56px;
    border-top: 1px solid $main-200;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 80px;

    @include breakpoint(lg) {
      column-gap: 30px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &_text {
      font-size: calc(20px + 4 * ((100vw - 360px) / 1098));
      color: $main-200;
      max-width: 745px;

      @include breakpoint(xxl) {
        font-size: 24px;
      }

    }
  }

}

@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}