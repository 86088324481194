.index-means{
  padding: calc(100px + 50*((100vw - 360px) / 1098)) 0;

  @include breakpoint(xxl){
	padding: 150px 0;
  }

  &__container{
	position: relative;
  }

  &__title{
	text-align: center;
	margin-bottom: 40px;

	@include breakpoint(md){
	  margin-bottom: 0;
	  text-align: left;
	  position: absolute;
	  top: 0;
	  left: 1.5rem;
	  max-width: 300px;
	}
  }

  &__wrapper{

	@include breakpoint(md){
	  display: grid;
	  align-items: stretch;
	  width: 100%;
	  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	  gap: 32px;
	  transform: none!important;
	  transition: none!important;

	  .swiper-slide{
		width: 100%!important;

		&:nth-child(1) {
		  grid-column: 2 / span 1;
		  position: relative;
		  transition: all 1.5s ease 0s;
		}
	  }
  	}
  }

  &__swiper_nav{

	@include breakpoint(md){
	  display: none;
	}
  }
}