$accent-400: #F60;
$accent-300: #FFD700;

$main-500: #142140;
$main-400: #0056b3;
$main-300: #0078FF;
$main-200: #C6DAF7;
$main-100: #ECF4FF;

$base-400: #121316;
$base-300: #898f9f;

$border-color: #818181;

$black: #000;
$white: #fff;

